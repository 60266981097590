import { Markdown } from 'components/Markdown/Markdown';
import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { H2, SecondaryButton } from 'styles/Typography.styled';

export const LocalizationWrapper = styled.div`
    padding: 0 20px;
    display: flex;
    height: 100%;
    width: 100%;

    ${mediaQueries.sm} {
        flex-direction: column;
        max-height: unset;
    }
`;

export const SectionTitle = styled(H2)`
    margin-bottom: 24px;
`;

export const StyledText = styled(Markdown)`
    margin-bottom: 56px;
`;

export const DesktopImageWrapper = styled.div`
    width: fit-content;
    height: 100%;
    display: block;
`;

export const MobileImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: none;

    ${mediaQueries.sm} {
        display: block;
    }
`;

export const TextWrapper = styled.div`
    width: 100%;
    background-color: #f7fafd;
    padding: 82px 134px 82px 100px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;

export const DesktopImage = styled.img`
    max-width: 44%;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const Image = styled.img`
    width: 100%;

    ${mediaQueries.sm} {
        margin-bottom: 32px;
    }
`;

export const StyledButton = styled(SecondaryButton)`
    ${mediaQueries.xs} {
        margin: 0 auto;
        max-width: 280px;
        width: 100%;
    }
`;
