import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const GalleryWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            display: none;

            .my-custom-pagination-div {
                display: flex;
                justify-content: center;
                margin-top: 16px;
            }

            .swiper-pagination-bullet {
                background: ${colors.primaryBlue300} !important;
            }

            .swiper-pagination-bullet-active {
                background: ${colors.primaryBlue500} !important;
            }

            .pagination-div {
                text-align: center;
            }

            ${mediaQueries.sm} {
                display: block;
                margin-bottom: 28px;
            }
        `,
);

export const SwiperWrapper = styled(Swiper)`
    width: calc(100vw - 32px);
    margin-bottom: 10px;
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
