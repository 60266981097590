import { HomeGallerySlider } from 'components/HomeGallerySlider/HomeGallerySlider';
import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    GalleryLeftWrapper,
    GalleryRightWrapper,
    GalleryText,
    LeftImage,
    RightImage,
    SectionTitle,
    StyledButton,
    StyledSectionWrapper,
} from './HomeGallery.styled';

export interface HomeGalleryProps {
    displayButton?: boolean;
    gallery: { image: ImageType }[];
    galleryDescription: string;
    galleryTitle: string;
    investmentSlug: string;
}

export const HomeGallery = ({
    displayButton,
    gallery,
    galleryDescription,
    galleryTitle,
    investmentSlug,
}: HomeGalleryProps) => {
    return (
        <StyledSectionWrapper>
            <GalleryLeftWrapper>
                <SectionTitle>{galleryTitle}</SectionTitle>
                <GalleryText>{galleryDescription}</GalleryText>
                <HomeGallerySlider
                    images={gallery?.map(({ image }) => image?.sourceUrl)}
                />
                <LeftImage src={gallery && gallery[0]?.image?.sourceUrl} />
                {displayButton && (
                    <StyledButton to={'/' + investmentSlug + '/galeria'}>
                        Zobacz całą galerię
                    </StyledButton>
                )}
            </GalleryLeftWrapper>
            <GalleryRightWrapper>
                <RightImage src={gallery && gallery[1]?.image?.sourceUrl} />
                <RightImage src={gallery && gallery[2]?.image?.sourceUrl} />
            </GalleryRightWrapper>
        </StyledSectionWrapper>
    );
};
