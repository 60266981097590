import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import {
    SectionWrapper,
    H2,
    SecondaryButton,
    SectionText,
} from 'styles/Typography.styled';

export const SectionTitle = styled(H2)`
    margin-bottom: 24px;
`;

export const AboutDeveloper = styled(SectionText)(
    ({ theme: { fontSizes } }) => css`
        margin-bottom: 24px;
        font-size: ${fontSizes.f18};
        line-height: 32px;
    `,
);

export const DesktopImageWrapper = styled.div`
    display: block;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const MobileImageWrapper = styled.div`
    display: none;

    ${mediaQueries.sm} {
        display: block;
        margin: 32px 0 40px;
    }
`;

export const Image = styled.img`
    ${mediaQueries.xs} {
        width: 100%;
    }
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
    padding: 180px 20px 130px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;

export const TextWrapper = styled.div`
    margin-right: 110px;
    width: 100%;

    ${mediaQueries.sm} {
        margin-right: 0;
    }
`;

export const StyledButton = styled(SecondaryButton)`
    margin-top: 56px;

    ${mediaQueries.xs} {
        margin: 0 auto;
        max-width: 280px;
        width: 100%;
    }
`;
