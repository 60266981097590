import { NumberWithText } from 'components/NumberWithText/NumberWithText';
import React from 'react';
import { ImageType, TitleDesriptionType } from 'types/CommonTypes';
import {
    AboutInvestmentButton,
    DesktopImageWrapper,
    Image,
    InfoBox,
    InfoWrapper,
    MobileImageWrapper,
    RightWrapper,
    StyledSectionText,
    StyledSectionWrapper,
    StyledTitle,
} from './AboutInvestment.styled';

export interface AboutInvestmentProps {
    aboutInvestmentDescription: string;
    aboutInvestmentImageDesktop: ImageType;
    aboutInvestmentImageMobile: ImageType;
    aboutInvestmentKeyFeatures: TitleDesriptionType[];
    aboutInvestmentTitle: string;
    investmentSlug: string;
}

export const AboutInvestment = ({
    aboutInvestmentDescription,
    aboutInvestmentImageDesktop,
    aboutInvestmentImageMobile,
    aboutInvestmentKeyFeatures,
    aboutInvestmentTitle,
    investmentSlug,
}: AboutInvestmentProps) => {
    return (
        <StyledSectionWrapper>
            <DesktopImageWrapper>
                <Image src={aboutInvestmentImageDesktop?.sourceUrl} />
            </DesktopImageWrapper>
            <RightWrapper>
                <StyledTitle>{aboutInvestmentTitle}</StyledTitle>
                <StyledSectionText>
                    {aboutInvestmentDescription}
                </StyledSectionText>
                <MobileImageWrapper>
                    <Image src={aboutInvestmentImageMobile?.sourceUrl} />
                </MobileImageWrapper>
                <InfoBox>
                    {aboutInvestmentKeyFeatures?.map((item, index) => (
                        <InfoWrapper key={index}>
                            <NumberWithText
                                number={item.title}
                                text={item.description}
                            />
                        </InfoWrapper>
                    ))}
                </InfoBox>
                <AboutInvestmentButton
                    to={'/' + investmentSlug + '/o-inwestycji'}
                >
                    Dowiedz się więcej
                </AboutInvestmentButton>
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
