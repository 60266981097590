import { EmptyState } from 'components/EmptyState/EmptyState';
import { FlatCard } from 'components/FlatCard/FlatCard';
import { HomeTable } from 'components/Tables/HomeTable';
import React from 'react';
import { H2, PrimaryButton } from 'styles/Typography.styled';
import { Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { SingleFlatType } from 'types/CommonTypes';
import {
    FlatsWrapper,
    SectionDescription,
    SwiperWrapper,
    TableSectionWrapper,
    TableWrapper,
} from './Flats.styled';

export interface FlatsProps {
    data: SingleFlatType[];
    flatsDescription: string;
    flatsTitle: string;
    investmentSlug: string;
}

export const Flats = ({
    data,
    flatsDescription,
    flatsTitle,
    investmentSlug,
}: FlatsProps) => {
    return (
        <FlatsWrapper>
            <H2>{flatsTitle}</H2>
            <SectionDescription>{flatsDescription}</SectionDescription>
            <TableSectionWrapper>
                <TableWrapper>
                    {data && (
                        <HomeTable
                            data={data}
                            investmentSlug={investmentSlug}
                        />
                    )}
                </TableWrapper>
                <SwiperWrapper
                    pagination={{
                        el: '.flat-pagination-div',
                        clickable: true,
                        renderBullet: ({ className }: any) => {
                            return (
                                '<span class="' + className + '">' + '</span>'
                            );
                        },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    {' '}
                    {!data?.length && (
                        <EmptyState text="Przepraszamy, nie mamy już wolnych mieszkań w tej inwestycji. Sprawdź nasze inne inwestycje" />
                    )}
                    {data?.map((flat, index) => (
                        <SwiperSlide key={index}>
                            <FlatCard
                                flatInfo={flat}
                                investmentSlug={investmentSlug}
                            />
                        </SwiperSlide>
                    ))}
                </SwiperWrapper>
                <div className="flat-pagination-div" />
            </TableSectionWrapper>
            <PrimaryButton to={'/' + investmentSlug + '/mieszkania'}>
                Zobacz wszystkie mieszkania
            </PrimaryButton>
        </FlatsWrapper>
    );
};
