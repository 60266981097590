import { SingleAdvantage } from 'components/SingleAdvantage/SingleAdvantage';
import React from 'react';
import { AdvantagesType } from 'types/CommonTypes';
import { BottomHomeHeroWrapper } from './BottomHomeHero.styled';

export interface BottomHomeHeroProps {
    data: AdvantagesType[];
}

export const BottomHomeHero = ({ data }: BottomHomeHeroProps) => {
    return (
        <BottomHomeHeroWrapper>
            {data?.map((item, index) => (
                <SingleAdvantage
                    key={index}
                    icon={item.icon}
                    text={item.description}
                    title={item.title}
                />
            ))}
        </BottomHomeHeroWrapper>
    );
};
