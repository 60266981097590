import React from 'react';
import { LinkType, ImageType } from 'types/CommonTypes';
import {
    AboutDeveloper,
    DesktopImageWrapper,
    Image,
    MobileImageWrapper,
    SectionTitle,
    StyledButton,
    StyledSectionWrapper,
    TextWrapper,
} from './GeneralContractor.styled';

export interface GeneralContractorProps {
    description: string;
    image: ImageType;
    link: LinkType;
    title: string;
}
export const GeneralContractor = ({
    description,
    image,
    link,
    title,
}: GeneralContractorProps) => {
    return (
        <StyledSectionWrapper>
            <TextWrapper>
                <SectionTitle>{title}</SectionTitle>
                <AboutDeveloper
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <MobileImageWrapper>
                    <Image src={image?.sourceUrl} />
                </MobileImageWrapper>
                <StyledButton to={link?.url}>{link?.title}</StyledButton>
            </TextWrapper>
            <DesktopImageWrapper>
                <Image src={image?.sourceUrl} />
            </DesktopImageWrapper>
        </StyledSectionWrapper>
    );
};
