import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import {
    SectionWrapper,
    SecondaryButton,
    H2,
    SectionText,
} from 'styles/Typography.styled';

export const DesktopImageWrapper = styled.div`
    display: block;
    width: 100%;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const MobileImageWrapper = styled.div`
    display: none;

    ${mediaQueries.sm} {
        display: block;
        margin: 32px 0 40px;
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 96px;
    width: 100%;

    ${mediaQueries.sm} {
        margin-left: 0px;
    }
`;

export const StyledTitle = styled(H2)`
    margin-bottom: 24px;
`;

export const StyledSectionText = styled(SectionText)`
    margin-bottom: 40px;
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
    padding: 148px 20px 180px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;

export const Image = styled.img`
    width: 100%;
`;

export const InfoBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;

    ${mediaQueries.xs} {
        gap: 32px 20px;
        margin: 0 auto 30px;
        max-width: 280px;
        flex-wrap: wrap;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${mediaQueries.xs} {
        min-width: 70px;
    }
`;

export const AboutInvestmentButton = styled(SecondaryButton)`
    ${mediaQueries.xs} {
        margin: 0 auto;
        max-width: 280px;
        width: 100%;
    }
`;
