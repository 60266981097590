import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H4 } from 'styles/Typography.styled';

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 350px;

    ${mediaQueries.sm} {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ColumnTitle = styled(H4)(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.neutral900};
            margin-bottom: 16px;
            ${mediaQueries.sm} {
                font-size: ${fontSizes.f16};
            }
        `,
);

export const ImageWrapper = styled.div`
    margin-bottom: 10px;

    img {
        max-width: 40px;
        max-height: 40px;
    }
`;

export const Text = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.paleSky};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.medium};
            letter-spacing: ${letterSpacing.m};
            line-height: 22px;
        `,
);
