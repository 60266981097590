import React from 'react';
import { Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import {
    GalleryWrapper,
    Image,
    SwiperWrapper,
} from './HomeGallerySlider.styled';

interface HomeGallerySliderProps {
    images: string[];
}

export const HomeGallerySlider = ({ images }: HomeGallerySliderProps) => {
    return (
        <GalleryWrapper>
            <SwiperWrapper
                pagination={{
                    el: '.pagination-div',
                    clickable: true,
                    renderBullet: (index, className) => {
                        return '<span class="' + className + '">' + '</span>';
                    },
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {images?.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Image src={image} />
                    </SwiperSlide>
                ))}
            </SwiperWrapper>
            <div className="pagination-div" />
        </GalleryWrapper>
    );
};
