import React from 'react';
import { InfoBigNumber, Text } from 'styles/Typography.styled';
import { InfoWrapper } from './NumberWithText.styled';

interface NumberWithTextProps {
    bigger?: boolean;
    text: String;
    number: String;
}
export const NumberWithText = ({
    bigger,
    text,
    number,
}: NumberWithTextProps) => {
    return (
        <InfoWrapper bigger={bigger}>
            <InfoBigNumber>{number}</InfoBigNumber>
            <Text>{text}</Text>
        </InfoWrapper>
    );
};
