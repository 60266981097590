import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { InfoBigNumber, Text } from 'styles/Typography.styled';

export const InfoWrapper = styled.div<{ bigger?: boolean }>(
    ({ theme: { fontSizes }, bigger }) => css`
        display: flex;
        flex-direction: column;

        ${mediaQueries.xs} {
            height: 100%;

            ${bigger &&
            css`
                width: 132px;
                ${InfoBigNumber} {
                    font-size: ${fontSizes.f40};
                }
                ${Text} {
                    font-size: ${fontSizes.f14};
                }
            `}
        }
    `,
);
