import { Markdown } from 'components/Markdown/Markdown';
import React from 'react';
import { PrimaryButton, SectionText } from 'styles/Typography.styled';
import { LinkType, ImageType } from 'types/CommonTypes';
import {
    Container,
    DescriptionWrapper,
    HomeHeroWrapper,
    Image,
    RightWrapper,
    TextWrapper,
    Title,
} from './HomeHero.styled';

export interface HomeHeroProps {
    headerDescription: string;
    headerImage: ImageType;
    headerTitle: string;
    investmentSlug: string;
}

export const HomeHero = ({
    headerDescription,
    headerImage,
    headerTitle,
    investmentSlug,
}: HomeHeroProps) => {
    return (
        <Container>
            <HomeHeroWrapper>
                <TextWrapper>
                    <Title>{headerTitle}</Title>
                    <DescriptionWrapper>
                        <Markdown markdown={headerDescription} />
                        <PrimaryButton
                            to={'/' + investmentSlug + '/mieszkania'}
                        >
                            Znajdź swoje mieszkanie
                        </PrimaryButton>
                    </DescriptionWrapper>
                </TextWrapper>
            </HomeHeroWrapper>
            <RightWrapper>
                <Image
                    alt={headerImage?.altText}
                    src={headerImage?.sourceUrl}
                />
            </RightWrapper>
        </Container>
    );
};
