import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    DesktopImage,
    Image,
    LocalizationWrapper,
    MobileImageWrapper,
    SectionTitle,
    StyledButton,
    StyledText,
    TextWrapper,
} from './Localization.styled';

export interface LocalizationProps {
    investmentSlug?: string;
    locationDescription: string;
    locationImage: ImageType;
    locationTitle: string;
}
export const Localization = ({
    investmentSlug,
    locationDescription,
    locationImage,
    locationTitle,
}: LocalizationProps) => {
    return (
        <LocalizationWrapper>
            <DesktopImage src={locationImage?.sourceUrl} />

            <TextWrapper>
                <SectionTitle>{locationTitle}</SectionTitle>
                <StyledText
                    markdown={locationDescription}
                    smallText
                    darkColor
                />
                <MobileImageWrapper>
                    <Image src={locationImage?.sourceUrl} />
                </MobileImageWrapper>
                <StyledButton to={'/' + investmentSlug + '/lokalizacja'}>
                    Poznaj lokalizację
                </StyledButton>
            </TextWrapper>
        </LocalizationWrapper>
    );
};
