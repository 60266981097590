import React, { ReactElement } from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    ColumnTitle,
    ColumnWrapper,
    ImageWrapper,
    Text,
} from './SingleAdvantage.styled';

interface SingleAdvantageProps {
    icon: ImageType;
    text: string;
    title: string;
}

export const SingleAdvantage = ({
    icon,
    text,
    title,
}: SingleAdvantageProps) => {
    return (
        <ColumnWrapper>
            <ImageWrapper>
                <img src={icon?.sourceUrl} alt={icon?.altText} />
            </ImageWrapper>
            <ColumnTitle>{title}</ColumnTitle>
            {text && <Text>{text}</Text>}
        </ColumnWrapper>
    );
};
